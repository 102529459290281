<template lang="html">
    <div class="WatClickTest">
       <div class="WatClickTest--Header">
        Haz click en la zona que crees correcta, siguiendo las indicaciones de las tareas que te indica el sistema en cada momento
       </div>
       <div class="WatClickTest--Content">
            <div class="WatClickTest--Image">
                <img ref="ClickTestImage" :src="data.imageUrl" alt="" v-on:click="setAnswer($event)">
                <div class="WatClickTest--Pin" ref="ClickTestPin">
                    <svg width="40" height="56" viewBox="52 47 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1125_898)">
                        <g filter="url(#filter1_d_1125_898)">
                        <path d="M71.9394 47C66.653 47.006 61.5848 49.1087 57.8467 52.8467C54.1087 56.5848 52.006 61.653 52 66.9394C52 84.0012 70.1267 101.978 70.8994 102.517C71.2042 102.731 71.5673 102.845 71.9394 102.845C72.3115 102.845 72.6746 102.731 72.9794 102.517C73.7521 101.978 91.8788 84.0012 91.8788 66.9394C91.8728 61.653 89.7701 56.5848 86.0321 52.8467C82.294 49.1087 77.2258 47.006 71.9394 47Z" fill="#8134FF"/>
                        </g>
                        <path d="M66.9098 78.9847C66.4808 78.9847 66.0816 78.7399 65.8549 78.3372L60.3509 68.5639C59.989 67.9205 60.1674 67.075 60.7504 66.6749C61.3327 66.2755 62.0991 66.4721 62.4616 67.1163L66.8649 74.9346L74.6915 59.8134C75.0319 59.1561 75.7907 58.9274 76.3868 59.3034C76.9827 59.6791 77.1896 60.517 76.8495 61.1749L67.9888 78.2931C67.7717 78.7126 67.3708 78.9756 66.9326 78.9839C66.9253 78.9847 66.9174 78.9847 66.9098 78.9847Z" fill="white"/>
                        <path d="M72.7056 78.9845C72.2767 78.9845 71.8775 78.7398 71.6508 78.3371L66.1467 68.5638C65.7848 67.9204 65.9633 67.0749 66.5462 66.6748C67.1285 66.2754 67.8952 66.472 68.2574 67.1161L72.6607 74.9344L80.4873 59.8132C80.8277 59.156 81.5865 58.9273 82.1827 59.3033C82.7785 59.679 82.9854 60.5168 82.6453 61.1748L73.785 78.293C73.5675 78.7124 73.1666 78.9754 72.7288 78.9838C72.7212 78.9845 72.7132 78.9845 72.7056 78.9845Z" fill="white"/>
                        </g>
                    </svg>
                </div>
            </div>
       </div>
       <div class="WatClickTest--Footer">
        <button :disabled="disabledNextButton" v-on:click="finish()">
            {{ $t('Button_next') }}
            <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4444 1.46228L17 5.01784M17 5.01784L13.4444 8.57339M17 5.01784H1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
       </div>
    </div>
</template>
<script>
    import Swal from 'sweetalert2'
    export default {
    name: 'WatClickTest',
    props:{
        data:{
            type: Object,
            default: () => {}
        },
        infoText:{
            type:String,
            default:""
        }
    },
    computed:{
        disabledNextButton(){
            return this.answerX == null && this.answerY == null
        }
    },
    data(){
        return{
            answerX: null,
            answerY: null,
            executionTimeCounter: 0,
            executionInterval: null
        }
    },
    methods:{
        setAnswer(event){
            const image = this.$refs.ClickTestImage
            const pin = this.$refs.ClickTestPin

            pin.style.display = 'flex'

            const rect = image.getBoundingClientRect()

            const clickPositionX = event.clientX - rect.left
            const clickPositionY = event.clientY - rect.top

            const newPinPositionX = clickPositionX - pin.offsetWidth / 2
            const newPinPositionY = clickPositionY - pin.offsetHeight

            pin.style.left = newPinPositionX / image.width * 100 + "%"
            pin.style.top = newPinPositionY / image.height * 100 + "%"
            pin.style.visibility = 'visible'

            this.answerY = clickPositionY  / image.height
            this.answerX = clickPositionX  / image.width
        },
        initExecutionTime(){
            let vm = this
            this.executionTimeCounter = this.data.timeoutSeconds
            setTimeout(() => {
                vm.startExecutionTime()
            }, 1000);
        },
        startExecutionTime(){
            let vm = this
            this.executionInterval = setInterval(function() {
                if(vm.executionTimeCounter == 0) return vm.executionTimeFinished()
                vm.executionTimeCounter--
            }, 1000);
        },
        executionTimeFinished(){
            clearInterval(this.executionInterval)
            let vm = this
            Swal.fire({
                text:vm.$t('watux_quanti_finish_message'),
                icon:"info",
                showCancelButton: false,
                showConfirmButton: true,
                allowOutsideClick: false
            })
           this.finish()
        },
        finish(){
            this.$emit('finished',{answerX: this.answerX, answerY: this.answerY})
        },

    },
    mounted(){
        this.initExecutionTime()
    }
}

</script>
    
    
<style lang="scss">
    .WatClickTest{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        &--Header{
            height: 56px;
            width: 100%;
            background-color: #F6F0FF;
            color: #2D0077;
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 24px;
        }
        &--Content{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &--Image{
            position: relative;
        }
        &--Footer{
            height: 75px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 5px 50px 0px #1F1F1F0D;
        }
        &--Pin{
            position: absolute;
            display: none;
            visibility: hidden;
        }
        img{
            max-width: 100%;
            height: auto;
            max-height: calc(100vh - 100px);
            border: 1px solid lightgray;
            cursor: pointer;
        }
        button{
            background-color: var(--button-color);
            width: fit-content;
            height: 43px;
            padding: 12px 24px;
            border-radius: 9999px;
            color: white;
            border-width: 0px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            &:disabled{
                opacity: 0.2;
            }
        }
        @media screen and (min-width: 770px) {
            &--Header{
                font-size: 18px;
                height: 60px;
            }
            &--Footer{
                height: 126px;
            }
            button{
                height: 62px;
                font-size: 20px;
            }
        }
    }
</style>
          